import jQuery from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import 'owl.carousel2';
import 'jquery.scrollbar';
import Scrollbar from 'smooth-scrollbar';
import SmoothScroll from 'smooth-scroll';
import Swiper from 'swiper';
import '@fancyapps/fancybox';
import '../vendor/jquery.maskedinput.min.js';
import '../vendor/select2/select2.min.js';
import '../vendor/selectize/selectize.min.js';


jQuery(function () {
	jQuery(document).ready(function () {


		jQuery('.main-slider').owlCarousel({
			items: 1,
			loop: true,
			//autoplay: true,
			smartSpeed: 1500,
			animateOut: 'fadeOut',
			navText: ['<svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.7"><path d="M0.357965 16.8241C-0.119322 16.369 -0.119322 15.631 0.357965 15.1759L15.9136 0.34137C16.3909 -0.113789 17.1647 -0.113789 17.642 0.34137C18.1193 0.796528 18.1193 1.53449 17.642 1.98965L2.08637 16.8241C1.60909 17.2793 0.835251 17.2793 0.357965 16.8241Z" fill="white"/><path d="M17.642 31.6586C17.1647 32.1138 16.3909 32.1138 15.9136 31.6586L0.357965 16.8241C-0.119322 16.369 -0.119322 15.631 0.357965 15.1759C0.835251 14.7207 1.60909 14.7207 2.08637 15.1759L17.642 30.0104C18.1193 30.4655 18.1193 31.2035 17.642 31.6586Z" fill="white"/></g></svg>'
				, '<svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.7"><path d="M17.642 15.1759C18.1193 15.631 18.1193 16.369 17.642 16.8241L2.08637 31.6586C1.60909 32.1138 0.835251 32.1138 0.357965 31.6586C-0.119322 31.2035 -0.119322 30.4655 0.357965 30.0104L15.9136 15.1759C16.3909 14.7207 17.1647 14.7207 17.642 15.1759Z" fill="white"/><path d="M0.357965 0.341369C0.835251 -0.11379 1.60909 -0.11379 2.08637 0.341369L17.642 15.1759C18.1193 15.631 18.1193 16.369 17.642 16.8241C17.1647 17.2793 16.3909 17.2793 15.9136 16.8241L0.357965 1.98965C-0.119322 1.53449 -0.119322 0.796528 0.357965 0.341369Z" fill="white"/></g></svg>'],
			responsive: {
				0: {
					nav: false,
				},
				576: {
					nav: true
				}
			}
		});

		jQuery('.documents-carousel').owlCarousel({
			loop: false,
			margin: 25,
			smartSpeed: 700,
			nav: true,
			items: 4,
			dots: false,
			captions: true,
			navText: ['<svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.366365 11.1675C-0.122122 11.6273 -0.122122 12.3727 0.366365 12.8325C0.854853 13.2923 1.64685 13.2923 2.13533 12.8325L13.6336 2.00986C14.1221 1.55008 14.1221 0.804621 13.6336 0.344837C13.1451 -0.114946 12.3532 -0.114946 11.8647 0.344837L0.366365 11.1675Z" fill="#181818"/><path d="M2.13533 11.1675C1.64685 10.7077 0.854853 10.7077 0.366365 11.1675C-0.122122 11.6273 -0.122122 12.3727 0.366365 12.8325L11.8647 23.6552C12.3532 24.1149 13.1451 24.1149 13.6336 23.6552C14.1221 23.1954 14.1221 22.4499 13.6336 21.9901L2.13533 11.1675Z" fill="#181818"/></svg>'
				, '<svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.6336 12.8325C14.1221 12.3727 14.1221 11.6273 13.6336 11.1675C13.1451 10.7077 12.3532 10.7077 11.8647 11.1675L0.366365 21.9901C-0.122122 22.4499 -0.122122 23.1954 0.366365 23.6552C0.854853 24.1149 1.64685 24.1149 2.13533 23.6552L13.6336 12.8325Z" fill="#181818"/><path d="M11.8647 12.8325C12.3532 13.2923 13.1451 13.2923 13.6336 12.8325C14.1221 12.3727 14.1221 11.6273 13.6336 11.1675L2.13533 0.344838C1.64685 -0.114946 0.854853 -0.114946 0.366365 0.344838C-0.122122 0.804621 -0.122122 1.55008 0.366365 2.00986L11.8647 12.8325Z" fill="#181818"/></svg>'],
			responsive: {
				0: {
					items: 2,
				},
				576: {
					items: 3,
				},
				1200: {
					items: 4,
				}
			}
		});

		jQuery('.news-heading-carousel').owlCarousel({
			loop: false,
			margin: 45,
			smartSpeed: 700,
			nav: true,
			items: 9,
			dots: false,
			autoWidth: true,
			navText: ['<svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.26169 7.9103C-0.0872299 8.23598 -0.0872299 8.76402 0.26169 9.0897C0.610609 9.41538 1.17632 9.41538 1.52524 9.0897L9.73831 1.42365C10.0872 1.09797 10.0872 0.569941 9.73831 0.244259C9.38939 -0.0814209 8.82368 -0.0814209 8.47476 0.244259L0.26169 7.9103Z" fill="#E6E6E6"/><path d="M1.52524 7.9103C1.17632 7.58462 0.610609 7.58462 0.26169 7.9103C-0.0872299 8.23598 -0.0872299 8.76402 0.26169 9.0897L8.47476 16.7557C8.82368 17.0814 9.38939 17.0814 9.73831 16.7557C10.0872 16.4301 10.0872 15.902 9.73831 15.5763L1.52524 7.9103Z" fill="#E6E6E6"/></svg>'
				, '<svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.73831 9.0897C10.0872 8.76402 10.0872 8.23598 9.73831 7.91031C9.38939 7.58462 8.82368 7.58462 8.47476 7.91031L0.26169 15.5764C-0.0872299 15.902 -0.0872299 16.4301 0.26169 16.7557C0.610609 17.0814 1.17632 17.0814 1.52524 16.7557L9.73831 9.0897Z" fill="#E6E6E6"/><path d="M8.47476 9.0897C8.82368 9.41538 9.38939 9.41538 9.73831 9.0897C10.0872 8.76402 10.0872 8.23598 9.73831 7.91031L1.52524 0.24426C1.17632 -0.08142 0.610609 -0.08142 0.26169 0.24426C-0.0872299 0.56994 -0.0872299 1.09797 0.26169 1.42365L8.47476 9.0897Z" fill="#E6E6E6"/></svg>'],
			responsive: {
				0: {
					items: 5,
					nav: false
				},

				992: {
					items: 9
				}
			}
		});

		jQuery('.articles-heading-carousel').owlCarousel({
			loop: false,
			margin: 45,
			smartSpeed: 700,
			nav: true,
			items: 9,
			dots: false,
			autoWidth: true,
			navText: ['<svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.26169 7.9103C-0.0872299 8.23598 -0.0872299 8.76402 0.26169 9.0897C0.610609 9.41538 1.17632 9.41538 1.52524 9.0897L9.73831 1.42365C10.0872 1.09797 10.0872 0.569941 9.73831 0.244259C9.38939 -0.0814209 8.82368 -0.0814209 8.47476 0.244259L0.26169 7.9103Z" fill="#E6E6E6"/><path d="M1.52524 7.9103C1.17632 7.58462 0.610609 7.58462 0.26169 7.9103C-0.0872299 8.23598 -0.0872299 8.76402 0.26169 9.0897L8.47476 16.7557C8.82368 17.0814 9.38939 17.0814 9.73831 16.7557C10.0872 16.4301 10.0872 15.902 9.73831 15.5763L1.52524 7.9103Z" fill="#E6E6E6"/></svg>'
				, '<svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.73831 9.0897C10.0872 8.76402 10.0872 8.23598 9.73831 7.91031C9.38939 7.58462 8.82368 7.58462 8.47476 7.91031L0.26169 15.5764C-0.0872299 15.902 -0.0872299 16.4301 0.26169 16.7557C0.610609 17.0814 1.17632 17.0814 1.52524 16.7557L9.73831 9.0897Z" fill="#E6E6E6"/><path d="M8.47476 9.0897C8.82368 9.41538 9.38939 9.41538 9.73831 9.0897C10.0872 8.76402 10.0872 8.23598 9.73831 7.91031L1.52524 0.24426C1.17632 -0.08142 0.610609 -0.08142 0.26169 0.24426C-0.0872299 0.56994 -0.0872299 1.09797 0.26169 1.42365L8.47476 9.0897Z" fill="#E6E6E6"/></svg>'],
			responsive: {
				0: {
					items: 5,
					nav: false
				},

				992: {
					items: 9
				}
			}
		});


		jQuery('.tour-types-carousel').owlCarousel({
			loop: false,
			smartSpeed: 700,
			nav: true,
			items: 7,
			dots: false,
			autoWidth: false,
			navText: ['<img width="6" height="12" style="transform: rotate(180deg)" src="/templates/assets/img/arrows/arrow-right-white.svg" alt="">'
				, '<img width="6" height="12" src="/templates/assets/img/arrows/arrow-right-white.svg" alt="">'],
			responsive: {
				0: {
					items: 4,
					nav: false,
					autoWidth: false,
					margin: 20,
				},
				576: {
					items: 5,
					nav: false,
					autoWidth: false,
					margin: 30,
				},
//				640: {
//					items: 4,
//					nav: false,
//					autoWidth: false
//				},
				768: {
					items: 5,
					margin: 25,
					nav: false,
				},
				992: {
					items: 7,
					margin: 20,
					autoWidth: false,
				},
				1050: {
					margin: 20
				},
				1150: {
					margin: 25
				},
				1250: {
					items: 7,
					margin: 30,
					autoWidth: false
				}
			}
		});

		// jQuery('.upcoming-tours-filter').owlCarousel({
		// 	loop: false,
		// 	margin: 25,
		// 	smartSpeed: 700,
		// 	nav: true,
		// 	items: 6,
		// 	dots: false,
		// 	navText: ['<img src="img/arrows/arrow-left-filter-orange.svg" alt="">', '<img src="img/arrows/arrow-right-filter-orange.svg" alt="">'],
		// 	responsive: {
		// 		0: {
		// 			items: 2,
		// 			margin: 15,
		// 			//nav: false
		// 		},
		// 		576: {
		// 			items: 3,
		// 			margin: 15,
		// 			//nav: false
		// 		},

		// 		768: {
		// 			items: 5
		// 		},

		// 		992: {
		// 			items: 6
		// 		}
		// 	}
		// });


		

		var scroll = new SmoothScroll('a[href*="#"]');
		jQuery('.selectize-dropdown-content').scrollbar();

		// Отправляет данные из формы на сервер и получает ответ
		jQuery('#feedbackForm').on('submit', function (event) {

			event.preventDefault();

			var form = $('#feedbackForm'),
				button = $('#feedbackFormButton'),
				answer = $('#answer'),
				loader = $('#loader');

			jQuery.ajax({
				url: 'vendor/handler.php',
				type: 'POST',
				data: form.serialize(),
				beforeSend: function () {
					answer.empty();
					button.attr('disabled', true);
					loader.fadeIn();
				},
				success: function (result) {
					loader.fadeOut(300, function () {
						answer.text(result);
					});
					form.find('.field').val('');
					button.attr('disabled', false);
				},
				error: function () {
					loader.fadeOut(300, function () {
						answer.text('Произошла ошибка! Попробуйте позже.');
					});
					button.attr('disabled', false);
				}
			});

		});

		//    -------------      form    --------------- //

		$('.btn-play').on('click', function () {
			$('#modal_form_layout').show();
			$('body').addClass('no-scroll');
		});
		// close modal on clicking close button
		$('.modal-form').find('.close').on('click', function () {
			$(this).parents('#modal_form_layout').hide();
			$('body').removeClass('no-scroll');
		});
		// close modal on click outside at anywhere
		$(document).on('click', function (e) {
			if (!(($(e.target).closest(".modal-form").length > 0) || ($(e.target).closest(".btn-play").length > 0))) {
				$("#modal_form_layout").hide();
				$('body').removeClass('no-scroll');
			}
		});


		$('.modal-from form .btn-brand').on('click', function () {
			$(this).parents(".modal-from").find(".success-send").css('display', 'flex');
		});

		$('.modal-from').on('hidden.bs.modal', function (e) {
			$(this).find(".success-send").hide();
		});


		$('#formSlideOut .btn-vertical').on('click', function () {
			let formformSlideOut = $(this).parent('#formSlideOut');
			formformSlideOut.toggleClass('open');
			if (!formformSlideOut.hasClass("open")) {
				$('.modal-connect-with-us__success-send').hide();
			}
		});



		$('.article-read-fully').on('click', function () {
			$(this).parents('.article-hidden').addClass('opened');
		});

		$('#newSubscription .modal-close-x, #newSubscription .news-subscription__refusal').on('click', function () {
			$('#newSubscription').removeClass('open');
		});

		$('#formSlideOut .form-slide-out__close, #formSlideOutLayOut').on('click', function () {
			$('#formSlideOut').removeClass('open');
		});

		$('.review__description-overlay.closed .review__read').on('click', function () {
			if ($(this).hasClass("review__read-gallery")){
				$(this).find("a")[0].click();
			} else {
				$(this).parent('.review__description-overlay.closed').removeClass('closed');
			}
		});

		$(".views-row").each(function(i) {

			$(this).eq(i).delay(800).addClass("active");
		  });
	});

	$(function () {
		'use strict'

		$('[data-toggle="offcanvas"]').on('click', function () {
			$('.offcanvas-collapse').toggleClass('open')
		})
	})


	//Блок кода для перехода по ссылке на нужный таб

	let url = location.href.replace(/\/$/, "");

	if (location.hash) {
		const hash = url.split("#");
		$('.nav-tabs a[href="#' + hash[1] + '"]').tab("show");
		url = location.href.replace(/\/#/, "#");
		history.replaceState(null, null, url);
		setTimeout(() => {
			$(window).scrollTop(0);
		}, 400);
	}

	$('a[data-toggle="tab"]').on("click", function () {
		let newUrl;
		const hash = $(this).attr("href");
		if (hash == "#home") {
			newUrl = url.split("#")[0];
		} else {
			newUrl = url.split("#")[0] + hash;
		}
		newUrl += "/";
		history.replaceState(null, null, newUrl);
	});


	jQuery('.form__input-phone').mask('+7 (999) 999 - 99 - 99');


	// input type file work

    let inputs = document.querySelectorAll('.input__file');
    Array.prototype.forEach.call(inputs, function (input) {
      let inputFileText = input.nextElementSibling;
	  let initialInputFileText = inputFileText.innerText;
      input.addEventListener('change', function (e) {
        let countFiles = '';
        if (this.files && this.files.length >= 1)
          countFiles = this.files.length;
  
        if (countFiles)
			inputFileText.innerText = 'Выбрано файлов: ' + countFiles;
        else
			inputFileText.innerText = initialInputFileText;
      });
    });

	//$('#js-example-basic-hide-search').select2({
	//	//minimumResultsForSearch: Infinity
	//});


	$("#select-city").selectize({
		items : [""],
		placeholder: "Поиск...",
	});


	// -------  Subscribe News  ----------- //

	(function showSubscribeNewsModal(){
		var visited = sessionStorage.getItem('visited');

		if (!visited) {
			setTimeout(function () {
				$('#newSubscription').addClass('open');
			}, 2000);
		}
	
		$('#newSubscription .modal-close-x, #newSubscription .news-subscription__refusal').on('click', function () {
			$('#newSubscription').removeClass('open');
			sessionStorage.setItem('visited', true);
		});
	})();
});

